import uniqid from 'uniqid'
import { troops } from '../../portfolio'
import TroopContainer from '../TroopContainer/TroopContainer'
import './Troops.css'

const Troops = () => {
  if (!troops.length) return null

  return (
    <section id='troops' className='section troops'>
      {/* <h2 className='section__title'>Troops</h2> */}

      <div className='troops__grid'>
        {troops.map((troop) => (
          <TroopContainer key={uniqid()} troop={troop} />
        ))}
      </div>
    </section>
  )
}

export default Troops

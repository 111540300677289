import './Footer.css'
import logo from './cornerstone-gmc-blu.png'

const Footer = () => (
  <footer className='footer'>
    Proudly chartered by <a
      href='https://ourcornerstone.org/'
      className='link footer__link'
        target='_blank'
        rel='noreferrer'
            >
    <img src={logo} alt="Cornerstone Global Methodist Church" height={40}/>

    </a>
  </footer>
)

export default Footer

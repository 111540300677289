import { useContext } from 'react'
import { ThemeContext } from './contexts/theme'
import Header from './components/Header/Header'
import Troops from './components/Troops/Troops'
import Skills from './components/Skills/Skills'
import Contact from './components/Contact/Contact'
import Footer from './components/Footer/Footer'
import './App.css'
import Oath from './components/Oath/Oath'
import AlertBanner from "./components/AlertBanner/AlertBanner";
import PhotoCarousel from "./components/PhotoCarousel/PhotoCarousel";

const App = () => {
  const [{ themeName }] = useContext(ThemeContext)
  const alertMessage = "Upcoming Open House  August 20, 2024. Activities begin at 7pm";

  return (
    <div id='top' className={`${themeName} app`}>
      <Header />
      <AlertBanner message={alertMessage} />
      <main>
          <PhotoCarousel />
          <Troops />
        <Oath />
        <Skills />
        <Contact />

      </main>
      <Footer />
    </div>
  )
}

export default App

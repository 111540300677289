import { oath } from '../../portfolio'
import './Oath.css'

const Oath = () => {
  const { title, description } = oath

  return (
    <section className='section oath center' id='oath'>
      <h2 className='oath__title'>{title}</h2>
       <p className='oath__desc'>{description && description}</p>
    </section>
  )
}

export default Oath
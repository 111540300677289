import { useState } from 'react'
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'
import { troops, contact } from '../../portfolio'
import './Navbar.css'

const Navbar = () => {
  const [showNavList, setShowNavList] = useState(false)

  const toggleNavList = () => setShowNavList(!showNavList)

  return (
    <nav className='center nav'>
      <ul
        style={{ display: showNavList ? 'flex' : null }}
        className='nav__list'
      >
        {troops.length ? (
          <li className='nav__list-item'>
            <a
              href='#troops'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Our Groups
            </a>
          </li>
        ) : null}

        {/* {skills.length ? (
          <li className='nav__list-item'>
            <a
              href='#skills'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Scout Law
            </a>
          </li>
        ) : null}
        
        {oath.length ? (
          <li className='nav__list-item'>
            <a
              href='#oath'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Scout Oath
            </a>
          </li>
        ) : null} */}

        {contact.email ? (
          <li className='nav__list-item'>
            <a
              href='#contact'
              onClick={toggleNavList}
              className='link link--nav'
            >
              Contact Us
            </a>
          </li>
        ) : null}
      </ul>
{/* 
      <button
        type='button'
        onclick={toggletheme}
        classname='btn btn--icon nav__theme'
        aria-label='toggle theme'
      >
        {themename === 'dark' ? <wbsunnyroundedicon /> : <brightness2icon />}
      </button> */}

      <button
        type='button'
        onClick={toggleNavList}
        className='btn btn--icon nav__hamburger'
        aria-label='toggle navigation'
      >
        {showNavList ? <CloseIcon /> : <MenuIcon />}
      </button>
    </nav>
  )
}

export default Navbar
import uniqid from 'uniqid'
import './TroopContainer.css'

const TroopContainer = ({ troop }) => (

  <div className='troop'>
   <h2 className='section__title'>{troop.name}</h2>
    <p className='troop__age'>{troop.age}</p>
    <p className='troop__gender'>{troop.gender}</p>
    <p className='troop__meetingtime'>Meetings: {troop.meetingtime}<br />{troop.meetingnote}</p>
    <p className='troop__description'>{troop.description}</p><br/>

    {troop.join && (
      <a
        href={troop.join}
        aria-label='Join Today'
        className='link link--icon'
        target='_blank'
        rel='noreferrer'
      >
        <img
          src={`./assets/images/${troop.patch}.png`}
          height={75}
          alt='Patch'
        />
                <p className='troop__join'>Click here to join today!</p>

      </a>
    )}

  </div>
)

export default TroopContainer

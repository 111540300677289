const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: 'https://ourcornerstonescouts.org/',
  title: 'Scouting America - Marysville, Ohio',
}

const oath = {
  // all the properties are optional - can be left empty or deleted
  title: 'Scout Oath',
  description:
    'On my honor I will do my best to do my duty to God and my country and to obey the Scout Law; to help other people at all times; to keep myself physically strong, mentally awake, and morally straight.',
}

const troops = [
  {
    name: 'Pack 101',
    patch: '101-pack-patch',
    age: 'Kindergarten – 5th Grade',
    gender: 'Boys and Girls Welcome',
    meetingtime: 'Tuesdays @ 7pm',
    meetingnote: '(Academic Calendar)',
    description:
      'A pack dedicated to providing a fun and educational scouting experience for young boys and girls. With a focus on character development, Pack 101 offers exciting activities such as camping, hiking, and hands-on learning. Led by supportive adult leaders, the pack encourages teamwork, leadership skills, and community involvement. Cub Scouts in Pack 101 build lifelong friendships and develop essential life skills in a welcoming and inclusive environment. The Pack includes not only the youth in the dens but also their families.',
    join: 'https://my.scouting.org/online-registration/6f041e0b-ed39-47f4-ba68-8ece6ab5cfaa/applicant-type',
  },
  {
    name: 'Troop 101',
    patch: '101-troop-patch',
    gender: 'Males',
    age: '5th Grade – 17 years',
    meetingtime: 'Tuesdays @ 6:30pm',
    description:
      'Thriving scouting community dedicated to the holistic development of young individuals. Through a wide range of engaging activities, including camping trips, merit badge workshops, and community service initiatives, the troop cultivates leadership skills, outdoor expertise, and a strong sense of camaraderie. With experienced adult leaders, Troop 101 provides a welcoming environment that empowers scouts to become well-rounded, responsible citizens.',
    join: 'https://my.scouting.org/online-registration/1fe477ba-5a46-490d-a4e4-326bb0c50942/applicant-type',
  },
  {
    name: 'Troop 777',
    patch: '777-troop-patch',
    gender: 'Females',
    age: '5th Grade – 17 years',
    meetingtime: 'Tuesdays @ 6:00pm',
    description:
      'Tight-knit scouting troop fostering personal growth and camaraderie. With a focus on character building, outdoor adventures, and community service, the troop empowers young females to become confident and compassionate leaders. Led by dedicated adult leaders, Troop 777 offers diverse activities, from camping trips to leadership training, instilling valuable life skills for a successful future.',
    join: 'https://my.scouting.org/online-registration/06baa83e-f978-46b2-8042-4af1ce4ad465/applicant-type',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'Trustworthy',
  'Loyal',
  'Helpful',
  'Friendly',
  'Courteous',
  'Kind',
  'Obedient',
  'Cheerful',
  'Thrifty',
  'Brave',
  'Clean',
  'Reverent',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'recruiting@ourcornerstonescouts.org',
}

export { header, troops, skills, oath, contact }

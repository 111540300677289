import React,{ Component } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../../override.css';  // change this to the file path of your overrides

const PhotoCarousel = () => (

    <div className="slider-container">
        <Carousel className="carousel-style"
                  showArrows={false}
                  showIndicators={false}
                  showThumbs={false}
                  showStatus={false}
                  autoPlay
                  interval={3000}
                  infiniteLoop
        >
            <div className="slide"> <img alt="0" src="./assets/PhotoCarousel/0.jpg" key="0" /></div>
            <div className="slide"> <img alt="1" src="./assets/PhotoCarousel/1.jpg" key="0" /></div>
            <div className="slide"> <img alt="2" src="./assets/PhotoCarousel/2.jpg" key="0" /></div>
            <div className="slide"> <img alt="3" src="./assets/PhotoCarousel/3.jpg" key="0" /></div>
            <div className="slide"> <img alt="4" src="./assets/PhotoCarousel/4.jpg" key="0" /></div>
            <div className="slide"> <img alt="5" src="./assets/PhotoCarousel/5.jpg" key="0" /></div>
            <div className="slide"> <img alt="6" src="./assets/PhotoCarousel/6.jpg" key="0" /></div>
            <div className="slide"> <img alt="7" src="./assets/PhotoCarousel/7.jpg" key="0" /></div>
            <div className="slide"> <img alt="9" src="./assets/PhotoCarousel/9.jpg" key="0" /></div>
            <div className="slide"> <img alt="10" src="./assets/PhotoCarousel/10.jpg" key="0" /></div>
            <div className="slide"> <img alt="11" src="./assets/PhotoCarousel/11.jpg" key="0" /></div>
            <div className="slide"> <img alt="12" src="./assets/PhotoCarousel/12.jpg" key="0" /></div>
            <div className="slide"> <img alt="13" src="./assets/PhotoCarousel/13.jpg" key="0" /></div>
            <div className="slide"> <img alt="14" src="./assets/PhotoCarousel/14.jpg" key="0" /></div>
            <div className="slide"> <img alt="15" src="./assets/PhotoCarousel/15.jpg" key="0" /></div>
            <div className="slide"> <img alt="16" src="./assets/PhotoCarousel/16.jpg" key="0" /></div>
            <div className="slide"> <img alt="17" src="./assets/PhotoCarousel/17.jpg" key="0" /></div>
            <div className="slide"> <img alt="18" src="./assets/PhotoCarousel/18.jpg" key="0" /></div>
        </Carousel>
    </div>
)
export default PhotoCarousel

